import { ConfirmationOptions } from "primevue/confirmationoptions";
import { ToastMessageOptions } from "primevue/toast";
import { ToastServiceMethods } from "primevue/toastservice";
import { playError, playInfo, playNotification, playSuccess, playWarn } from "../utils/helpers";

export const useOverlayMessages = () => {
  const DEFAULT_TOAST_POSITION = "bottom-right";

  return {
    addToast: (toast: ToastServiceMethods, options: ToastMessageOptions, position?: "top-left" | "top-center" | "top-right" | "bottom-left" | "bottom-center" | "bottom-right" | "center") => {
      toast.add({ ...options, group: position ?? DEFAULT_TOAST_POSITION });

      switch (options.severity) {
        case "success":
          playSuccess();
          break;
        case "info":
          playInfo();
          break;
        case "warn":
          playWarn();
          break;
        case "error":
          playError();
          break;
        default:
          playNotification();
          break;
      }
    },
    addConfirmDialog: (confirm: { require: (option: ConfirmationOptions) => unknown; close: () => void }, options: ConfirmationOptions) => {
      return confirm.require({ ...options, group: "dialog" });
    },
  };
};
