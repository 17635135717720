import { ActionTree } from "vuex";
import { AuthState } from "./state";
import { StateInterface } from "../index";
import { NavigationFailure, RouteLocationRaw, Router } from "vue-router";
import { ToastServiceMethods } from "primevue/toastservice";
import { useOverlayMessages } from "../../composables/useOverlayMessages";
import { Http } from "../../services/Http";
import { User } from "../../models/User";
import { useLayoutModal } from "../../composables/useLayoutModal";
import ChangePassword from "../../modules/profile/components/ChangePassword.vue";
import { Severities } from "../../enums/Severities";

export type PushRouter = Partial<Router> & { push: (to: RouteLocationRaw) => Promise<void | NavigationFailure | undefined>; replace: (to: RouteLocationRaw) => Promise<void | NavigationFailure | undefined> };

const { open } = useLayoutModal();
const { addToast } = useOverlayMessages();
const http = new Http();

const actions: ActionTree<AuthState, StateInterface> = {
  async signIn({ commit, dispatch }, { email, password, toast, router }: { email: string; password: string; toast: ToastServiceMethods; router: PushRouter }) {
    commit("startLoading");

    await http.get<null>("csrf-cookie");

    const { data } = await http.post<undefined>("login", { email, password });

    if (data.success) {
      await dispatch("getMe", { toast, router });
    } else {
      addToast(toast, {
        severity: "warn",
        summary: "Invalid Credentials",
        detail: data.errors.message,
        closable: true,
        life: 10000, // 10 Seg
      });
    }

    commit("endLoading");
  },

  async getMe({ state, commit }, { router }: { router: PushRouter }) {
    const redirectToLastPage = (user: User) => {
      commit("setUser", { ...user, change_password_required: false });

      return router.replace(state.lastRoute);
    };

    commit("startLoading");

    const { data } = await http.get<User>("users/me?include=organization");

    if (data.success) {
      commit("setUser", data.entries);

      if (data.entries.change_password_required) {
        open(ChangePassword, "Must Change Password", { cancelable: false }, { success: () => redirectToLastPage(data.entries) }, { severity: Severities.Info, closable: false });
      } else {
        await redirectToLastPage(data.entries);
      }
    } else {
      commit("unsetUser");
    }

    commit("endLoading");
  },

  async signOut({ commit }, { toast, router }: { toast: ToastServiceMethods; router: PushRouter }) {
    const { data } = await http.post<undefined>("logout");

    if (data.success) {
      commit("unsetUser");

      await router.push({ name: "login" });
    } else {
      addToast(toast, {
        severity: "error",
        summary: "Logout Error",
        detail: data.errors,
        closable: true,
        life: 10000, // 10 Seg
      });
    }
  },
};

export default actions;
