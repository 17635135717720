import { DateTime } from "luxon";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { AxiosResponse } from "axios";
import { Filter } from "../components/Filter/interfaces/Filter";
import { FilterMethods } from "../components/Filter/enums/FilterMethods";
import { CalculatedCartItem, CartItem } from "../store/cart/state";
import { useOverlayMessages } from "../composables/useOverlayMessages";
import { ToastServiceMethods } from "primevue/toastservice";
import { FilterItemTypes } from "../components/Filter";

// ----- Validations -----
/**
 * Validate the values if it's present
 * @param value - value to validate.
 * @param validateFn - function to validate if the value is present
 */
export function unRequiredValue<T>(value: T, validateFn: (value: T) => boolean): boolean {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value === "string" && value === "") {
    return true;
  }

  return validateFn(value);
}

/**
 * Validate the values is present and valid.
 * @param value - value to validate.
 * @param validateFn - function to validate.
 */
export function requiredValue<T>(value: T, validateFn: (value: T) => boolean): boolean {
  if (value === undefined || value === null) {
    return false;
  }

  if (typeof value === "string" && value === "") {
    return false;
  }

  return validateFn(value);
}

/**
 * Validate if the two items are equal.
 * @param value - first item to compare.
 * @param compare - second item to compare.
 */
export function equal<T>(value: T, compare: T): boolean {
  return value === compare;
}

/**
 * min validate. if [string, array] >> length, [number] >> value, [File] >> size
 * @param value - element to validate min
 * @param min - min
 */
export function min(min: number, value?: string | number | File | unknown[]) {
  if (!value) return true;

  if (typeof value === "number") return value >= min;

  if (value instanceof File) return value.size >= min;

  return value?.length >= min;
}

/**
 * max validate. if [string, array] >> length, [number] >> value, [File] >> size
 * @param value - element to validate max
 * @param max - max
 */
export function max(max: number, value?: string | number | File | unknown[]) {
  if (!value) return true;

  if (typeof value === "number") return value <= max;

  if (value instanceof File) return value.size <= max;

  return value.length <= max;
}

export function regex(regexPattern: string | RegExp, value?: string) {
  if (!value) return true;

  return RegExp(regexPattern).test(value);
}

/**
 * validate if file is an image.
 * @param file - the ile to validate
 */
export function image(file: File, index = 0): boolean {
  return file.type?.split("/")[index] === "image";
}

/**
 * validate the length of string is between min and max inclusive.
 * @param str - the string to validate.
 * @param min - the min value range.
 * @param max - the mas value range.
 */
export function length(str: string, min: number, max: number): boolean {
  return str.length >= min && str.length <= max;
}

// ----- date -----
export function numberToDateTime(value: number, seconds = true): DateTime {
  return seconds ? DateTime.fromSeconds(value) : DateTime.fromMillis(value);
}

export function numberToDate(value: number, seconds = true): Date {
  return convertToDate(numberToDateTime(value, seconds));
}

export function convertToLocalZone(time: string, format: string, zone = "utc", outFormat?: string): string {
  return DateTime.fromFormat(time, format, { zone })
    .setZone("local")
    .toFormat(outFormat ?? format);
}

export function convertToUtc(time: string, format: string, outFormat?: string): string {
  return DateTime.fromFormat(time, format, { zone: "local" })
    .setZone("utc")
    .toFormat(outFormat ?? format);
}

export function convertToDateTime(dte: string | Date | DateTime): DateTime {
  if (typeof dte === "string") {
    return DateTime.fromISO(dte);
  }

  if (dte instanceof Date) {
    return DateTime.fromJSDate(dte);
  }

  return dte;
}

export function convertToDate(dte: string | Date | DateTime): Date {
  if (typeof dte === "string") {
    return DateTime.fromISO(dte).toJSDate();
  }

  if (dte instanceof DateTime) {
    return dte.toJSDate();
  }

  return dte;
}

// ----- format -----
export function toUnix(dte?: string | Date | DateTime, seconds = true): number {
  const dt = convertToDateTime(dte ?? DateTime.now());

  return seconds ? Math.floor(dt.toSeconds()) : dt.toMillis();
}

export function secondToTime(seconds: number): string {
  if (seconds <= 0) {
    return "0:00:00";
  }

  const hourSec = 3600;
  const minSec = 60;

  let rest = seconds;

  const H = Math.floor(rest / hourSec);
  rest = rest % hourSec;

  const m = Math.floor(rest / minSec);
  rest = rest % minSec;

  const s = rest;

  return `${H}:${m > 9 ? m : "0" + m}:${s > 9 ? s : "0" + s}`;
}

export function lengthFormat(srt: string, maxLength: number, ignore = false): string {
  if (ignore) {
    return srt;
  }

  if (maxLength > 0 && srt.length > maxLength) {
    return srt.trim().substring(0, maxLength).trim() + "...";
  }

  return srt.trim();
}

export function dateFormat(dte: string | Date | DateTime, format: string): string {
  return convertToDateTime(dte).toFormat(format);
}

export function dateStandardFormat(dte: string | Date | DateTime): string {
  return dateFormat(dte, "LL/dd/yyyy");
}

export function timeStandardFormat(dte: string | Date | DateTime): string {
  return dateFormat(dte, "hh:mm a");
}

export function dateTimeStandardFormat(dte?: string | Date | DateTime): string {
  if (dte === undefined || dte === null) {
    return "";
  }

  return dateFormat(dte, "LL/dd/yyyy hh:mm a");
}

export function ucFirst(str: string): string {
  if (str.length <= 1) {
    return str.toUpperCase();
  }

  return str.substring(0, 1).toUpperCase() + str.substring(1);
}

export function ucWord(sentence: string, separator = " "): string {
  const words = sentence.split(separator);

  return words.map((word) => ucFirst(word)).join(separator);
}

// ----- thread -----
export function sleep(ms = 0): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function run<T>(f: () => T): Promise<T> {
  return new Promise<T>((resolve) => setTimeout(() => resolve(f())));
}

// Todo: validate same logic as backend
export function hasAll<T>(self: T[], required: T[]): boolean {
  if (required.length > 0) {
    for (const item of required) {
      if (!self.includes(item)) {
        return false;
      }
    }
  }

  return true;
}

// Todo: validate same logic as backend
export function hasAny<T>(self: T[], required: T[]): boolean {
  if (required.length > 0) {
    for (const item of required) {
      if (self.includes(item)) {
        return true;
      }
    }

    return false;
  }

  return true;
}

// Todo: validate same logic as backend
export function hasAllOfAny(any: number[][], current: number[]) {
  for (const required of any) {
    if (hasAll(current, required)) {
      return true;
    }
  }

  return false;
}

export function toCurrency(value: number, currency = "USD", locales = "en-US"): string {
  return value.toLocaleString(locales, { style: "currency", currency });
}

function saveAsExcelFile(buffer: BlobPart, fileName?: string): void {
  const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";
  const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

  FileSaver.saveAs(data, (fileName ?? "export") + EXCEL_EXTENSION);
}

export function exportExcel(data: { [key: string]: unknown }[], cols: { field: string; header: string }[], fileName?: string): void {
  const rows = [];

  for (const item of data) {
    const row: { [key: string]: unknown } = {};
    for (const col of cols) {
      row[col.header] = item[col.field];
    }

    rows.push(row);
  }

  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  saveAsExcelFile(excelBuffer, fileName);
}

export function basicTypeToString(value: string | number | boolean | Date, format?: string | string[]): string {
  if (typeof value === "string" || typeof value === "number") {
    return value.toString();
  }

  if (typeof value === "boolean") {
    const [trueStr, falseStr] = Array.isArray(format) && format.length ? format : ["1", "0"];
    return value ? trueStr : falseStr ?? "0";
  }

  return dateFormat(value, Array.isArray(format) ? "yyyy-LL-dd" : format ?? "yyyy-LL-dd");
}

export function playSound(path: string): void {
  new Audio(path).play();
}

export function playError(): void {
  playSound("/assets/sounds/error.ogg");
}

export function playInfo(): void {
  playSound("/assets/sounds/info.ogg");
}

export function playNotification(): void {
  playSound("/assets/sounds/notification.ogg");
}

export function playSuccess(): void {
  playSound("/assets/sounds/success.ogg");
}

export function playWarn(): void {
  playSound("/assets/sounds/warn.ogg");
}

export function downloadBlob(response: AxiosResponse<Blob>, defaultName = "temp"): void {
  if (response.data) {
    const contentDisposition = response.headers["content-disposition"];
    const filenameMatch = contentDisposition ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/) : null;

    FileSaver.saveAs(response.data, filenameMatch ? filenameMatch[1] : defaultName);

    playSuccess();
  }
}

export function filterValueTostringValue(filter: Filter): string[] {
  const items = Array.isArray(filter.value) ? [...filter.value] : [filter.value];

  return items.map((item) => {
    if (typeof item === "string" || typeof item === "number") return item.toString();

    if (typeof item === "boolean") return item ? "1" : "0";

    const value = convertToDateTime(item).toUTC();

    if (!value.isValid) throw new Error("Invalid Filter Value");

    return filter.type === FilterItemTypes.datetime ? value.toFormat("yyyy-LL-dd HH:mm:ss") : value.toFormat("yyyy-LL-dd");
  });
}

export function filterToString(filter: Filter): string {
  let value = "";
  const stringValue = filterValueTostringValue(filter);

  switch (filter.method) {
    case FilterMethods.between:
      if (!Array.isArray(filter.value) || filter.value.length < 2) {
        throw new Error("The value is invalid for between filter method.");
      }

      value = filter.method.toString().replace("$$$", stringValue[0]).replace("%%%", stringValue[1]);
      break;
    case FilterMethods.isNotOneOf:
    case FilterMethods.isOneOf:
      value = stringValue.length ? filter.method.toString().replace("$$$", stringValue.join(",")) : "";
      break;
    default:
      if (stringValue.length) {
        value = filter.method.toString().replace("$$$", stringValue[0]);
      }
      break;
  }

  return `${filter.key}=${value}`;
}

export function filtersToString(filters: Filter[]): string {
  return filters.map(filterToString).join("&");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getNestedProps<T>(item: any, keys: string): T {
  if (!keys) throw new Error("keys must be a non-empty string.");

  if (typeof item !== "object") return item ?? undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return keys.split(".").reduce<any>((obj, key) => obj?.[key] ?? undefined, item);
}

export function checkCookie(name: string) {
  const cookies = document.cookie.split(";");

  for (const cookie of cookies) {
    if (cookie.startsWith(name + "=")) {
      // Cookie with the specified name exists
      return true;
    }
  }

  // Cookie with the specified name does not exist
  return false;
}

export function firstWord(sentence: string, separator = " ") {
  const words = sentence.split(separator);

  return words.length ? words[0] : sentence;
}

export function firstLastname(sentence: string) {
  let lastname = "";
  const words = sentence.split(" ");

  for (const word of words) {
    if (word.length > 3) {
      return `${lastname} ${word}`.trim();
    }

    lastname = `${lastname} ${word}`;
  }

  return sentence;
}

export function copyObjectWithValue(item: { [key: string]: unknown }): { [key: string]: unknown } {
  const obj: { [key: string]: unknown } = {};

  for (const key in item) {
    if (Object.prototype.hasOwnProperty.call(item, key)) {
      const property = item[key];

      if (property !== null && property !== undefined && property !== 0) {
        obj[key] = property;
      }
    }
  }

  return obj;
}

export function calculatedCartItem(item: CartItem): CalculatedCartItem {
  const extras = item.selectedOptionValues
    .map((value) => {
      if (value) {
        if (Array.isArray(value)) {
          return value.reduce<number>((preview, val) => preview + (val.extra ? val.extra : 0), 0);
        }

        return value.extra ? value.extra : 0;
      }

      return 0;
    })
    .reduce((p, c) => p + c, 0);

  return {
    ...item,
    taxFactor: 1 + (item.apply_tax ? item.tax_factor : 0),
    subtotal: (item.price + extras) * item.quantity,
  };
}

export function trimCharacter(str: string, character = " "): string {
  const regex = new RegExp(`^${character}+|${character}+$`, "g");

  const a = str.replace(regex, "");

  return a;
}

export function readBlobAsJson(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(JSON.parse(reader.result?.toString() ?? ""));
    };

    reader.onerror = (error) => {
      reject(error);
    };

    // Read the Blob as text
    reader.readAsText(blob);
  });
}

export function errorMessageDisplay(errors: { [key: string]: string | string[] }, toast: ToastServiceMethods, summary?: string) {
  const { addToast } = useOverlayMessages();

  for (const label in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, label)) {
      const element = errors[label];

      const messages = Array.isArray(element) ? element : [element];

      for (const message of messages) {
        addToast(toast, {
          severity: "error",
          summary: label === "message" ? summary ?? "Error" : label,
          detail: message,
          closable: true,
          life: 10000,
        });
      }
    }
  }
}

export function militaryToTime(military: number) {
  let time = military.toString();
  while (time.length < 4) time = `0${time}`;

  return `${time.substring(0, 2)}:${time.substring(2)}:00`;
}

export function timeToMilitary(time: string) {
  return parseInt(time.replaceAll(":", ""));
}
