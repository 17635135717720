<script setup lang="ts">
import { withDefaults, defineProps, defineEmits } from "vue";
import { useFileInput } from "../composables/useFileInput";

declare type FileInputProp = {
  /**
   * takes as its value a comma-separated list of one or more file types, or unique file type specifiers, describing which file types to allow. Like accept in &lt;input type=&quot;file&quot; /&gt;
   */
  accept?: string;

  /**
   * a space-separated list of the case-sensitive classes of the element.
   */
  class?: string;

  /**
   * a boolean which indicates whether the control is disabled.
   */
  disabled?: boolean;

  /**
   * for v-model support
   */
  modelValue?: File | File[];

  /**
   * a boolean which indicates the file input allows the user to select more than one file
   */
  multiple?: boolean;

  /**
   * name of element
   */
  name?: string;

  /**
   * defines the text displayed in a form control when the control has no value.
   */
  placeholder?: string;

  /**
   * when present, makes the element not mutable, meaning the user can not edit the control
   */
  readonly?: boolean;
};

const props = withDefaults(defineProps<FileInputProp>(), {
  accept: "",
  disabled: false,
  multiple: false,
  readonly: false,
});

const emit = defineEmits(["update:modelValue"]);

const { input, highlight, files, formatSize, objectURL, change, removeFile, dropFile, dragEnter, dragLeave } = useFileInput(props, emit);
</script>

<template>
  <input ref="input" type="file" @change="change" style="display: none" :accept="accept" :multiple="multiple" :name="name" />

  <Panel :class="`${readonly || disabled ? 'readonly' : ''} ${props.class}`">
    <template #header>
      <div class="flex flex-wrap justify-content-between align-items-center flex-1" v-tooltip.top="placeholder">
        <Button class="btn-file" @click="input?.click()" icon="pi pi-file" rounded outlined v-tooltip="'Change Selected Files'" :disabled="readonly || disabled"></Button>

        <Button class="btn-clear" @click="emit('update:modelValue', undefined)" icon="pi pi-trash" severity="danger" rounded outlined v-tooltip.left="'Remove All Selected Files'" :disabled="readonly || disabled"></Button>
      </div>
    </template>

    <div
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @dragover="dragEnter"
      @drop="dropFile"
      class="draggable p-2 max-h-15rem overflow-x-hidden overflow-y-auto"
      :class="{ 'border-2 border-primary border-round-sm border-dashed': highlight }"
      v-tooltip.bottom="placeholder"
    >
      <div class="grid" v-if="files.length">
        <div v-for="(file, index) of files" :key="file.name + file.type + file.size" class="file-row m-0 px-1 py-2 col-6 flex border-1 surface-border align-items-center">
          <img role="presentation" :alt="file.name" :src="objectURL(file)" height="64" class="shadow-2" v-if="file.type.startsWith('image')" />
          <i class="fa-solid fa-file-code text-orange-500" style="font-size: 64px" v-else-if="file.type === 'text/html'"></i>
          <i class="fa-solid fa-file-audio text-blue-500" style="font-size: 64px" v-else-if="file.type.startsWith('audio')"></i>
          <i class="fa-solid fa-file-video text-500" style="font-size: 64px" v-else-if="file.type.startsWith('video')"></i>
          <i class="fa-solid fa-file-pdf text-red-500" style="font-size: 64px" v-else-if="file.type === 'application/pdf'"></i>
          <i class="fa-solid fa-file-lines text-600" style="font-size: 64px" v-else-if="file.type === 'application/rtf'"></i>
          <i class="fa-solid fa-file-code text-primary" style="font-size: 64px" v-else-if="file.type === 'application/xml'"></i>
          <i class="fa-solid fa-file-zipper text-yellow-500" style="font-size: 64px" v-else-if="file.type === 'application/zip'"></i>
          <i class="fa-solid fa-file-zipper text-red-400" style="font-size: 64px" v-else-if="file.type === 'application/vnd.rar'"></i>
          <i class="fa-solid fa-file-zipper text-700" style="font-size: 64px" v-else-if="file.type === 'application/x-7z-compressed'"></i>
          <i class="fa-solid fa-file-csv text-green-600" style="font-size: 64px" v-else-if="file.type === 'text/csv'"></i>
          <i class="fa-solid fa-file-excel text-green-600" style="font-size: 64px" v-else-if="file.type === 'application/vnd.ms-excel'"></i>
          <i class="fa-solid fa-file-excel text-green-600" style="font-size: 64px" v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"></i>
          <i class="fa-solid fa-file-word text-blue-400" style="font-size: 64px" v-else-if="file.type === 'application/msword'"></i>
          <i class="fa-solid fa-file-word text-blue-400" style="font-size: 64px" v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"></i>
          <i class="fa-solid fa-file-powerpoint text-red-300" style="font-size: 64px" v-else-if="file.type === 'application/vnd.ms-powerpoint'"></i>
          <i class="fa-solid fa-file-powerpoint text-red-300" style="font-size: 64px" v-else-if="file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'"></i>
          <i class="fa-solid fa-file text-primary" style="font-size: 64px" v-else></i>

          <div class="mx-2">
            <span class="font-semibold block max-w-24rem" style="word-wrap: break-word">{{ file.name }}</span>

            <div class="size-render">{{ formatSize(file.size) }}</div>
          </div>
          <Button icon="pi pi-times" @click="removeFile(index)" rounded text severity="danger" size="small" class="ml-auto mr-1 mt-0 mb-auto" :disabled="readonly || disabled"></Button>
        </div>
      </div>

      <div class="flex align-items-center justify-content-center flex-column" v-else>
        <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400"></i>
        <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
      </div>
    </div>
  </Panel>
</template>

<style scoped lang="scss">
.readonly {
  mask-image: linear-gradient(rgba(0, 0, 0, 0.41), rgba(0, 0, 0, 0.4));
}
</style>

<style lang="scss">
.p-panel.p-invalid {
  .p-panel-header {
    border-top-color: var(--red-500);
    border-left-color: var(--red-500);
    border-right-color: var(--red-500);
  }

  .p-panel-content {
    border-bottom-color: var(--red-500);
    border-left-color: var(--red-500);
    border-right-color: var(--red-500);
  }
}
</style>
